// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src154454834/src/website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-js": () => import("/codebuild/output/src154454834/src/website/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-blog-js": () => import("/codebuild/output/src154454834/src/website/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src154454834/src/website/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src154454834/src/website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src154454834/src/website/.cache/data.json")

